@font-face {
    font-family: "AirbnbCereal";
    src: url("../fonts/AirbnbCereal-Light.ttf") format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: "AirbnbCereal";
    src: url("../fonts/AirbnbCereal-Book.ttf") format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: "AirbnbCereal";
    src: url("../fonts/AirbnbCereal-Medium.ttf") format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: "AirbnbCereal";
    src: url("../fonts/AirbnbCereal-Bold.ttf") format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: "AirbnbCereal";
    src: url("../fonts/AirbnbCereal-ExtraBold.ttf") format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: "AirbnbCereal";
    src: url("../fonts/AirbnbCereal-Black.ttf") format('truetype');
    font-weight: 800;
}

body {
    font-family: "AirbnbCereal", "Avenir Next", "Harmonia Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 200;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    font-family: "AirbnbCereal", "Avenir Next", "Harmonia Sans", "Helvetica Neue", Arial, sans-serif;
}

@media (min-width: 992px){
    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}
.sebm-google-map-container {
    height: 300px;
}
#map{
    position:relative;
    width:100%;
    height: calc(100% - 60px);
    margin-top: 70px;
}

.places-buttons .btn{
    margin-bottom: 30px
}

.space-70{
    height: 70px;
    display: block;
}

.tim-row{
    margin-bottom: 20px;
}

.tim-typo{
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
}
.tim-typo .tim-note{
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}
.tim-row{
    padding-top: 50px;
}
.tim-row h3{
    margin-top: 0;
}
@media (min-width: 992px){

    .typo-line{
        padding-left: 140px;
        margin-bottom: 40px;
        position: relative;
    }

    .typo-line .category{
        transform: translateY(-50%);
        top: 50%;
        left: 0px;
        position: absolute;
    }
}
[class^='ais-'] {
    font-size: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }

.ais-SearchBox { margin-bottom: 1em }

.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Hits-item,
.ais-Results-item {
    margin-top: 1rem;
    margin-left: 1rem;
    padding: 1rem;
    width: calc(25% - 1rem);
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none; }

.card-nested [data-header-animation-nested="true"] {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.card-nested:hover [data-header-animation-nested="true"] {
    -webkit-transform: translate3d(0, -50px, 0);
    -moz-transform: translate3d(0, -50px, 0);
    -o-transform: translate3d(0, -50px, 0);
    -ms-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
}

.marker {
    margin-top:-3px;
    padding: 5px;
    position: absolute;
    visibility: visible;
    z-index: 1030;
}
.marker.active .inner {
    background: cyan;
}
.marker.hover .inner {
    background-color: yellow;
}
.marker .arrow{
    border-top-color: #000000;
    border-right-color: rgba(0,0,0,0);
    border-bottom-color: rgba(0,0,0,0);
    border-left-color: rgba(0,0,0,0);
    border-width: 5px 5px 0;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;
}
.marker .inner{
    background-color: #000000;
    border-radius: 4px;
    color: #FFFFFF;
    max-width: 400px;
    padding: 3px 8px;
    text-align: center;
    text-decoration: none;
}

.marker.active .arrow {

    border-top-color: #00ffff;
    border-right-color: rgba(0,255,255,0);
    border-bottom-color: rgba(0,255,255,0);
    border-left-color: rgba(0,255,255,0);

}
.marker.hover .arrow {
    border-top-color: #ffff00;
    border-right-color: rgba(255,255,0,0);
    border-bottom-color: rgba(255,255,0,0);
    border-left-color: rgba(255,255,0,0);
}

.strikethrough {
    text-decoration: line-through;
    text-decoration-color: red;
}

.lock-page .page-header .container-xl,
.lock-page .page-header .container-lg,
.lock-page .page-header .container-md,
.lock-page .page-header .container-sm,
.lock-page .page-header .container {
    max-width: 100% !important;
    padding-top: 0 !important;
}

.ratio-4-3{
    width:100%;
    position:relative;
    background:url('data:image/svg+xml; charset=US-ASCII, %3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%0A%20%20%20%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20d%3D%22M6.76%2C8l4.51-4.21a0.45%2C0.45%2C0%2C0%2C0%2C0-.66l-1.06-1a0.53%2C0.53%2C0%2C0%2C0-.71%2C0L4.68%2C6.64l-0.05%2C0-1.06%2C1a0.47%2C0.47%2C0%2C0%2C0%2C0%2C.68l1.06%2C1%2C0.05%2C0%2C4.82%2C4.5a0.53%2C0.53%2C0%2C0%2C0%2C.71%2C0l1.06-1a0.45%2C0.45%2C0%2C0%2C0%2C0-.66Z%22%2F%3E%0A%3Cdefs%3E%3Cstyle%3Epath%20%7B%0A%20%20%20%20%20%20fill%3A%20rgb%280%2C%200%2C%200%29%3B%0A%20%20%20%20%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3C%2Fsvg%3E%0A') 50% 50% no-repeat;
    background-size:cover;
}
.ratio-4-3:before{
    display:block;
    content:"";
    padding-top:75%;
}

.rounded{border-radius:.50rem!important}


